import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Divider } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { ResultsLineStatus } from '../../../../store/testCase';
import { StatusSymbol } from '../../../../utils/constants';

const useStyles = makeStyles(() => ({
  titleContainer: {
    padding: '5px 2px',
    cursor: 'pointer',
    '& h3': {
      fontWeight: 600,
      fontSize: '.9em',
    },
    display: 'flex',
    justifyContent: 'start',
    transition: 'background-color 0.5s',
    backgroundColor: grey[200],
    paddingRight: '5px',
    position: 'relative',
    zIndex: 10,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 4px 0px',
  },
  icon: {
    transition: 'transform 0.5s',
    transform: 'rotate(-90deg)',
    marginTop: '-3px',
  },
  iconClosed: {
    transform: 'rotate(0deg)',
    marginTop: '-2px',
  },
  status: {
    width: '29px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    flexGrow: 1,
  },
}));

type ExpandablePanelProps = {
  heading: string;
  isCollapsed?: boolean;
  onToggleCollapse?: (collapsed: boolean) => void;
  overallStatus?: ResultsLineStatus;
};

const ExpandablePanel: React.FC<ExpandablePanelProps> = ({
  heading,
  isCollapsed = false,
  children,
  onToggleCollapse,
  overallStatus,
}) => {
  const classes = useStyles();
  const [collapsed, setToggleCollapsed] = useState(isCollapsed);

  useEffect(() => {
    setToggleCollapsed(isCollapsed);
  }, [isCollapsed]);

  const handleHeadingClick = () => {
    onToggleCollapse && onToggleCollapse(!collapsed);
    setToggleCollapsed((prev) => !prev);
  };

  const statusSymbol =
    (overallStatus && overallStatus !== ResultsLineStatus.NA && (
      <motion.span
        animate={{ opacity: 1 }}
        initial={{ opacity: 0.4 }}
        style={{
          color:
            overallStatus === ResultsLineStatus.FAIL_REQUIRED || overallStatus === ResultsLineStatus.FAIL_UNWANTED
              ? red[700]
              : green[700],
        }}
      >
        {StatusSymbol[overallStatus]}
      </motion.span>
    )) ||
    '';

  const Title = (
    <div onClick={handleHeadingClick} className={classes.titleContainer} title={collapsed ? 'Expand' : 'Collapse'}>
      <div className={classes.status}>{statusSymbol}</div>
      <h3 className={classes.heading}>{heading}</h3>
      <ArrowLeftIcon
        className={classNames(classes.icon, {
          [classes.iconClosed]: collapsed,
        })}
      />
    </div>
  );

  return (
    <div>
      {Title}
      <div
        style={{
          marginTop: collapsed ? '0px' : '1px',
          minHeight: collapsed ? '0px' : '6rem',
          backgroundColor: '#ffff',
        }}
      >
        <div style={{ height: collapsed ? 0 : '100%' }}>{children}</div>
      </div>
      <Divider />
    </div>
  );
};

export { ExpandablePanel };
