import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Toolbar } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';

import { trackEvent, util } from '../../../../services';
import { closeResultsPanel } from '../../../../store/setting';
import { ToolbarIconButton } from '../ToolbarIconButton/ToolbarIconButton';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    marginLeft: '31px',
    fontWeight: 600,
  },
}));

const ResultsToolbar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleCollapse = () => {
    dispatch(closeResultsPanel());
    trackEvent.resultsPanelClosed();
    util.fireSplitPaneResizeEvent(Math.random());
  };

  return (
    <AppBar position="static" color="inherit" sx={{ backgroundColor: grey[200], zIndex: 10 }}>
      <Toolbar variant="dense" disableGutters={true}>
        <div className={classes.grow}>
          <span className={classes.title}>Results</span>
        </div>
        <div>
          <ToolbarIconButton aria-label="close results panel" onClick={handleCollapse} title="Close">
            <CloseIcon fontSize="small" />
          </ToolbarIconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export { ResultsToolbar };
