import { Toolbar } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { styled } from '@mui/styles';

const ToolbarVertical = styled(Toolbar)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundColor: grey[200],
  height: '100%',
  padding: '5px 0',
  transition: 'all 0.5s 0.2s',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: grey[300],
  },
});

export { ToolbarVertical };
